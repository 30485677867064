/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes documents véto';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mes documents véto';

export const alveoleLogoSvg = "./../images/icon-alv-docs-veto.svg";
/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-mesdocumentsveto-dev.cristal-hub.fr/customer-documents";
export const zUrlBack = "https://api-mesdocumentsveto-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const urlZeenDoc = "https://www.preprod.crm-cristal.fr/enjinapi/Zeendoc"

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";
export const Typedoc = [
    {id: 3, title: 'BSE - Protocoles de soins'},
    {id: 1, title: 'Ordonnances'},
    {id: 2, title: 'Rapports de visites'},
    {id: 4, title: 'Autres documents'},
];
export const Typedocid = [1, 2, 3, 4];
/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
